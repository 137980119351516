.hero {
  @include z-index('hero');
  max-height: 1080px;
  position: relative;
}

.hero__video,
.hero__image img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.play-icon {
  @include position(absolute, 50% null null 50%);
  transform: translate(-50%, -50%);

  @include breakpoint(above, 'large') {
    font-size: 85px;
  }

  & path {
    transition-duration: get-timing(normal);
    transition-property: fill, stroke;
  }

  &__triangle {
    fill: $white;
  }

  &__background {
    fill: transparent;
    stroke: $white;
  }
}

.hero__media {
  height: 260px;
  justify-content: center;

  @include breakpoint('above', 'x-small') {
    height: 300px;
  }

  @include breakpoint('above', 'small') {
    height: 350px;
  }

  @include breakpoint('above', 'large') {
    height: 440px;
  }

  @include breakpoint('above', 'xx-large') {
    height: 520px;
  }

  @include breakpoint('above', 'xxx-large') {
    @include aspect-ratio(16, 5);
    height: auto;
  }
}

.hero__image {
  height: 100%;
  width: 100%;

  @include breakpoint('above', 'xxx-large') {
    position: absolute;
  }

  &.js-with-popup {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    .play-icon__triangle {
      fill: $accent;
    }

    .play-icon__background {
      stroke: $accent;
    }
  }

  &::before {
    @include position(absolute, 0);
    background-image:
      linear-gradient(
        to bottom,
        transparentize($black, .75),
        $transparent
      );
    content: '';
    display: block;
  }
}

.hero__text {
  @include position(absolute, 50% null null 50%);
  @include z-index('hero', +1);
  color: $white;
  font-size: 19px;
  line-height: 29px;
  max-width: 900px;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);

  @include breakpoint('above', 'x-small') {
    font-size: 20px;
    line-height: 30px;
  }

  @include breakpoint('above', 'large') {
    font-size: 23px;
    line-height: 33px;
  }

  @include breakpoint('above', 'xxx-large') {
    font-size: 25px;
    line-height: 35px;
  }
}

.hero__popup {
  @include hide-visually;
}

// =============================================================================
// Full Height Heroes
// -----------------------------------------------------------------------------

.front,
.node-type-error-page,
.node-type-thank-you-page {
  .hero {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
  }

  .hero__media {
    @include position(absolute, 0);
    height: 100%;

    // 1: We're full height, so no need to follow an aspect ratio
    @include breakpoint('above', 'xxx-large') {
      &::before {
        content: none; // 1
      }
    }
  }
}
