@mixin thumbnail {
  position: relative;

  .field-item {
    &::after {
      @include breakpoint(above, 'medium') {
        @include position(absolute, null 0 0);
        background-image:
          linear-gradient(
            to top,
            transparentize($black, .7),
            $transparent
          );
        content: '';
        display: block;
        height: 90px;
      }

      @include breakpoint(above, 'large') {
        height: 100px;
      }

      @include breakpoint(above, 'xx-large') {
        height: 115px;
      }

      @include breakpoint(above, 'xxx-large') {
        height: 150px;
      }
    }
  }

  img,
  .object-fit-image {
    @include position(absolute, 0);
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
