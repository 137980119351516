// 1: We need to replace the top margin with padding to ensure that the dark
//    background covers the entire area.
.paragraphs-item-text {
  @include container(800px);

  & .drop-cap + .field-item {
    @include responsive-margin(left, $scale: $spacing--drop-cap, $to: 'large');

    @include breakpoint(above, 'large') {
      margin-left: 0;
    }
  }
}

// =============================================================================
// Contact Us page
// -----------------------------------------------------------------------------

.node-7 {
  .paragraphs-item-text .content {
    &::after {
      @include responsive-margin(top);
      border-bottom: 1px solid $gray;
      content: '';
      display: block;
      width: 100%;
    }
  }
}
