// 1: Prevent Safari from making buttons look silly :|
button,
[type='button'],
[type='submit'] {
  appearance: none; // 1
  border: 0;
  padding: 0;
}

// 1: Drupal styles set the button font family to `sans-serif` by default
.button,
.field-name-cta-button a {
  @include padding(x, 25px);
  align-items: center;
  background-color: $accent;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit; // 1
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  transition: background-color get-timing(normal) ease-in-out;

  @include breakpoint(above, 'small') {
    @include padding(x, 30px);
    font-size: 16px;
    height: 50px;
  }

  &:hover,
  &:focus {
    background-color: $accent-hover;
    color: $white;
  }

  &:active {
    color: $white;
    outline: 0;
  }
}

.button--secondary {
  background-color: $light-gray;
}
