$pager-item-width: 20px;
$pager-item-height: $pager-item-width;


// =============================================================================

.pagination {
  @include container(800px, '.pager');
  width: 100%;
}

.pagination__title {
  @include hide-visually;
}

.pager {
  @include responsive-margin(top);
  @include responsive-padding(top);
  @include no-bullets;
  align-items: center;
  border-top: 1px solid $dark-gray;
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  line-height: 1;
  width: 100%;

  a {
    @include hover-with-color;
  }
}


// =============================================================================
// Nav
// -----------------------------------------------------------------------------

.pager__prefix,
.pager__suffix {
  margin: 0;
  padding: 0;
}

// 1: Disable the cursor by default.
.pager__nav {
  color: $light-gray;
  cursor: not-allowed; // 1
  display: block;
  font-family: $font-family--icon;
  font-size: 28px;
  line-height: $pager-item-height;
}

.pager__nav a {
  color: $dark-gray;
  cursor: pointer;
  display: inline-block;
}

.pager__nav--previous {
  margin-right: 20px;
}

.pager__nav--next {
  margin-left: 20px;
}


// =============================================================================
// Items
// -----------------------------------------------------------------------------

.pager__pages {
  @include no-bullets;
  display: flex;
}

.pager__item {
  margin-left: 10px;

  &.first {
    margin-left: 0;
  }
}

.pager__item a,
.pager__item--current,
.pager__item--ellipsis {
  @include rectangle($pager-item-width, $pager-item-height);
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.pager__item a {
  @include hover-with-color;
  color: $light-gray;
}

.pager__item--current {
  color: $gray;
  cursor: default;
}

.pager__item--ellipsis {
  color: $light-gray;
  cursor: default;
}
