// 1: We need to replace the top margin with padding to ensure that the dark
//    background covers the entire area.
.paragraphs-item-text-two-column {
  @include responsive-margin(top, -1); // 1
  @include responsive-padding(all);
  @include dark-background;

  .field-items {
    @include no-bullets;
    @include responsive-margin(left, -1, $from: 'large');
    @include margin(y, 0);
    display: flex;
    flex-wrap: wrap;
  }

  .field-item {
    @include responsive-padding(left, $from: 'large');
    @include p-spacing;
    width: percentage(1 / 1);

    @include breakpoint(above, 'large') {
      margin-bottom: 0;
      width: percentage(1 / 2);
    }

    &:only-child {
      padding-left: 0;
    }
  }

  .drop-cap + .field-item {
    @include responsive-margin(left, $scale: $spacing--drop-cap, $to: 'large');

    @include breakpoint(above, 'large') {
      margin-left: 0;
    }
  }
}


// =============================================================================
// Thank You Page
// -----------------------------------------------------------------------------

.node-thank-you-page {
  .paragraphs-item-text-two-column {
    margin-top: 0;
  }
}
