@mixin tile {
  margin-bottom: 1px;
  padding-left: 1px;
  width: percentage(1 / 1);

  @include breakpoint(above, 'x-small') {
    width: percentage(1 / 2);
  }

  @include breakpoint(above, 'large') {
    width: percentage(1 / 4);
  }
}

@mixin tile-inner {
  align-items: flex-end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  position: relative;

  &::before,
  &::after {
    @include z-index('tile-overlay');
    content: '';
    display: block;
  }

  &::before {
    @include position(absolute, 0);
    background-color: $accent-overlay;
    opacity: 0;
    transition: opacity get-timing(normal) ease-in-out;
  }

  &::after {
    @include position(absolute, null 0 0);
    background-image:
      linear-gradient(
        to top,
        transparentize($black, .2),
        $transparent
      );
    height: 115px;
    transition: height get-timing(normal) ease-in-out;
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 1;
    }

    &::after {
      // Uncomment to make the gradient grow to the full height of the container
      // height: 100%;
    }
  }
}

@mixin tile-arrow {
  color: $white;
  content: '\2197'; // North east arrow
  display: block;
  font-family: $font-family--icon;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity get-timing(normal) ease-in-out;
}
