.tabs {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.action-links {
  @include no-bullets;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
}
