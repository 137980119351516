.menu-panel {
  @include position(absolute, 0 null 0 0);
  @include z-index('masthead');
  // background-color: $white;
  background-color: transparentize($white, .15);
  transform: translateX(-100%);
  transition: transform get-timing(normal) ease-in;
  width: 240px;

  @include breakpoint(above, 'x-small') {
    width: 340px;
  }

  &.is-open {
    transform: none;
  }
}

.menu-panel__menu {
  max-height: 100vh;
  overflow-y: auto;
  padding: 10px 40px 40px;

  @include breakpoint(above, 'x-small') {
    padding: 20px 75px 75px;
  }
}


// =============================================================================
// Logo
// -----------------------------------------------------------------------------

// 1: Some browsers (IE, older Safari) don't scale inline SVGs properly, so we
//    need to use the padding-based aspect ratio as a hacky workaround.
//
// 2: IE 11 doesn't support CSS transitions on SVG properties, so this
//    transition will never show up in that browser.
//
//    @see https://developer.microsoft.com/en-us/microsoft-edge/platform/status/csstransitionsforsvgelements/
.menu-panel__logo {
  @include aspect-ratio(458, 62); // 1
  display: block;
  margin-bottom: 40px;
  max-width: 100%;

  svg {
    @include position(absolute, 0);
    height: 100%;
    width: 100%;
  }

  path {
    transition: fill get-timing(normal) ease-in-out; // 2
  }

  .logo__letters {
    fill: $light-gray;
  }

  .logo__tower {
    fill: $gray;
  }

  &:hover,
  &:focus {
    path {
      fill: $accent;
    }
  }
}


// =============================================================================
// Body Styles
// -----------------------------------------------------------------------------

.menu-overlay {
  @include z-index('masthead', -1);
  background-color: $black;
  height: 0;
  opacity: 0;
  transition: opacity get-timing(normal) ease-in-out;

  &.is-open {
    @include position(fixed, 0 0);
    height: auto;
    opacity: .5;
  }
}


// =============================================================================
// Front Page
// -----------------------------------------------------------------------------

.front {
  .menu-panel {
    background-color: transparentize($white, .15);
  }
}

// =============================================================================
// Body Styles
// -----------------------------------------------------------------------------

// Adjust the top position when the admin menu is visible
body.adminimal-menu .menu-panel {
  top: 48px;

  @include breakpoint('above', 1024px) {
    top: 28px;
  }
}
