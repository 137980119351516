.disclaimer {
  @include responsive-margin(bottom);
  @include disclaimer-text;
}

// =============================================================================
// Property Disclaimer
// -----------------------------------------------------------------------------

.disclaimer--property-contact {
  @include p-spacing;
  @include p-spacing-top;

  margin-bottom: 0;
}

// =============================================================================
// Privacy Disclaimer
// -----------------------------------------------------------------------------

.disclaimer--privacy {
  @include responsive-padding(x);

  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}
