$font-size--special-offer-superscript: 12px;

.property--special-offers {
  // @include responsive-margin(bottom);
  // @include responsive-padding(left, $scale: $spacing--drop-cap);
  @include p-spacing($remove-last: false);

  .title {
    @include hide-visually;
  }
}


// =============================================================================
// Special Offers
// -----------------------------------------------------------------------------

$special-offer-counter: counter--special-offer;

.field-name-special-offers {
  counter-reset: #{$special-offer-counter};

  > .field-items {
    @include no-bullets;

    margin: 0;
  }
}

.node-special-offer {
  @include p-spacing(margin, $remove-last: false);

  border: 3px solid $accent;
  padding: 25px;

  .field,
  .field-item {
    display: inline;
  }
}

.special-offer__title {
  color: $accent;
  margin-bottom: 10px;
}
