// =============================================================================
// Available Properties List
// -----------------------------------------------------------------------------

.view-properties {
  @include dark-background;
  @include z-index('drop-cap', +1);
  position: relative;

  & > .title {
    @include hide-visually;
  }
}

.property-list {
  @include no-bullets;
  // Uncomment to make the last line have a different color than the background
  // background-color: $gray;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -1px;
}

// Views break BEM underscores :(
.property-list--property {
  @include tile;

  @include breakpoint(below, 'small') {
    width: percentage(1 / 1);
  }

  // Client request:
  // Make property tiles 2 column all the way to 1200, then switch them to 4 column.
  @include breakpoint(above, 'large') {
    width: percentage(1 / 2);
  }

  @include breakpoint(above, 'x-large') {
    width: percentage(1 / 4);
  }

  // Featured Properties need to be wider than normal ones
  &.is-featured {
    @include breakpoint(above, 'small') {
      width: percentage(1 / 1);
    }

    @include breakpoint(above, 'x-large') {
      width: percentage(1 / 2);
    }
  }
}


// =============================================================================
// Teaser Tiles
// -----------------------------------------------------------------------------

.property-teaser {
  @include tile-inner;
  height: 350px;

  &.is-sold {
    pointer-events: none;
  }
}

.property-teaser__featured-flag {
  @include z-index('tile-overlay', +1);
  @include position(absolute, 50px null null 0);
  align-items: center;
  background-color: $accent;
  color: $white;
  display: inline-flex;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 30px 15px 0;
  text-transform: uppercase;
  width: auto;

  & .icon {
    @include margin(x, 20px);
    font-size: 20px;
  }
}

.property-teaser .field-name-thumbnail {
  @include thumbnail;
  @include position(absolute, 0);
}

// 1: Ensure text appears over the tile overlay
// 2: Fix text rendering bugs in Safari
.property-teaser__inner {
  @include z-index('tile-overlay', +1); // 1
  position: relative;
  transform: translateZ(0); // 2

  &::before {
    @include tile-arrow;
  }

  .property-teaser:hover &,
  .property-teaser:focus & {
    &::before {
      opacity: 1;
    }
  }
}

.property-teaser.with-hidden-text {
  .property-teaser__inner {
    // Tile padding + Line height of property details line
    $translate-y: 20px + 28px;
    transform: translate3d(0, $translate-y, 0);
    transition: transform get-timing(normal) ease-in-out;
  }

  // @HACK
  //
  // 1: If we set this to `none` -- which would be the correct choice --  Safari
  //    renders the text _really_ poorly. Using `translateZ(0)` fixes this.
  &:hover,
  &:focus {
    .property-teaser__inner {
      transform: translateZ(0); // 1
    }
  }
}

.property-teaser__title,
.property-teaser__location {
  color: $accent;
  font-size: 16px;
  font-weight: normal;
}

.property-teaser__location {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.property-teaser__detail-list,
.property-teaser__detail--standalone {
  @include no-bullets;
  margin: 20px 0 0;
}

.property-teaser__detail {
  color: $white;
  display: inline;
  font-family: 500;
  font-size: 16px;

  &:not(:last-child) {
    &::after {
      @include margin(x, .5em);
      content: '|';
      display: inline-block;
    }
  }
}

.property-teaser__detail--standalone {
  display: inline-block;
}

// =============================================================================
// Available Properties
// -----------------------------------------------------------------------------

.page__header + .view-properties,
.view-properties.view-display-id-residential_sold {
  @include responsive-padding(top);
}
