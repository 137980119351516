/// Adds a growing accent-colored underline when the item is hovered
@mixin hover-with-underline {
  display: inline-block;
  outline: 0;
  transition-duration: get-timing(normal);
  transition-property: color;
  transition-timing-function: ease-in-out;

  &::after {
    @include rectangle(100%, 1px);
    background-color: $accent;
    content: '';
    display: block;
    position: relative;
    top: -4px;
    transform: scaleX(0);
    transform-origin: center left;
    transition-duration: inherit;
    transition-property: transform;
    transition-timing-function: inherit;
  }

  &:hover,
  &:focus {
    color: $accent;

    &::after {
      transform: none;
    }
  }
}
