////
/// Variables related to animation
/// @group Animation
////

/// Default transition speed
$timings: (
  'fast':         150ms,
  'normal':       250ms,
  'medium':       500ms,
  'slow':         750ms,
  'x-slow':      1250ms,
);
