.menu-name-main-menu {
  .menu__tree {
    @include no-bullets;
  }

  // 1: Dropdown-only links are text by default, but we still want users to know
  //    they can be interacted with
  .menu__link {
    color: $gray;
    cursor: pointer; // 1
    display: inline-block;
    transition: color get-timing(normal) ease-in-out;
  }


  // =============================================================================
  // First Level
  // -----------------------------------------------------------------------------

  .menu__item--level-1 {
    font-size: 21px;
    font-weight: 500;
    line-height: 45px;

    @include breakpoint(above, 'x-small') {
      font-size: 25px;
      line-height: 55px;
    }
  }

  .is-open > .menu__link--level-1,
  .menu__link--level-1:hover,
  .menu__link--level-1:focus {
    color: $accent;
  }

  // =============================================================================
  // Second Level
  // -----------------------------------------------------------------------------

  .menu__submenu {
    margin-left: 10px;
  }

  .menu__item--level-2 {
    font-size: 15px;
    font-weight: normal;
    height: 0;
    line-height: 32px;
    overflow: hidden;
    transition: height get-timing(normal);

    @include breakpoint(above, 'x-small') {
      font-size: 16px;
      line-height: 35px;
    }
  }

  .menu__item--level-1.is-open .menu__item--level-2 {
    height: 32px;

    @include breakpoint(above, 'x-small') {
      height: 35px;
    }
  }
}

.menu__link--level-2 {
  @include hover-with-underline;
}
