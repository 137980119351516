// =============================================================================
// General Colors
// -----------------------------------------------------------------------------

$accent: #7ad71f;
$accent-hover: #61ac18;
$accent-overlay: transparentize($accent, .6);

$error:#ff6464;
$warning: #f0a202;
// $warning: #f18805;

$black: #111;
$dark-gray: #363636;
$gray: #505050;
$light-gray: #aaa;
$off-white: #ebebeb;
$white: #fff;

$transparent: rgba(0, 0, 0, 0);


// =============================================================================
// Derived Colors
// -----------------------------------------------------------------------------

/// Color for lines dividing e.g. sidebar from main content
$divider-color: $off-white;

$page-background: $light-gray;
