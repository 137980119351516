.property-contact-form {
  background: $dark-gray;
  box-shadow: 0;
  transition: box-shadow get-timing(slow) ease-in-out;

  &.is-highlighted {
    box-shadow: 0 0 100px 25px $accent;
  }
}

.property-contact-form__title {
  @include h2-like;
  @include margin(y, 40px);

  color: $white;
}
