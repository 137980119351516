.property__details {
  @include responsive-padding(all);

  align-items: center;
  background: $dark-gray;
  flex-wrap: wrap;
  position: relative;

  @include breakpoint('above', 'x-large') {
    display: flex;
    justify-content: space-between;
  }

  .scroll-to-form {
    margin-top: 40px;

    @include breakpoint(above, 'x-large') {
      margin-top: 60px;
    }
  }
}

.property__overview {
  color: $white;
}

.property__detail-list {
  @include no-bullets;
  margin: 0;
}

.property__detail {
  display: inline;
  font-family: 500;

  &:not(:last-child) {
    &::after {
      @include margin(x, .5em);
      content: '|';
      display: inline-block;
    }
  }
}

.property__location {
  font-weight: 300;
  margin-bottom: 50px;
}
