body {
  @include body-text;
}


// =============================================================================
// Headings
// -----------------------------------------------------------------------------

h1 {
  @include h1-like;
}

h2 {
  @include h2-like;
}

h3 {
  @include h3-like;
}

@include headings {
  &:first-child {
    margin-top: 0;
  }

  a {
    color: inherit;
  }
}


// =============================================================================
// Text Blocks
// -----------------------------------------------------------------------------

p {
  @include p-spacing;
}

address {
  @include p-spacing($remove-last: false);
  font-style: normal;
}


// =============================================================================
// Links
// -----------------------------------------------------------------------------

a {
  text-decoration: none;
}

a:not([class]),
a[class=''] {
  @include hover-with-color;
  color: inherit;
}


// =============================================================================
// Special Formatted Text
// -----------------------------------------------------------------------------

.rtecenter {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.rteleft {
  text-align: left;
}
