.property__gallery {
  @include z-index('drop-cap', +1);
  position: relative;
  text-align: center;
}

.gallery {
  &.is-justified {
    .gallery__image {
      position: absolute;

      img {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.gallery__image-list {
  @include no-bullets;
  margin: 0;
  overflow: hidden;
  position: relative;

  &--overflow {
    height: 0;
    margin-top: 1px;
  }
}

.gallery__image a {
  @include position(absolute, 0);
  display: block;

  &::before,
  &::after {
    @include z-index('tile-overlay');
    opacity: 0;
    transition: opacity get-timing(normal) ease-in-out;
  }

  &::before {
    @include position(absolute, 0);
    background-color: $accent-overlay;
    content: '';
  }

  &::after {
    @include tile-arrow;
    @include position(absolute, null null 20px 20px);
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    &::before,
    &::after {
      opacity: 1;
    }
  }
}

.gallery__toggler {
  @include margin(y, 30px);
}
