#user-login {
  @include responsive-margin(y);
  @include container(800px, '> div');

  > div {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$webform-column-gap;
  }

  .form-item {
    padding-left: -$webform-column-gap;
    width: percentage(1 / 2);
  }

  .form-actions {
    width: 100%;
  }

  .description {
    @include hide-visually;
  }
}
