// 1: This would be more natural to put on the container around the first row,
//    but there seems to be a bug in IE & Edge that calculates the width of a
//    container incorrectly when it has (1) negative margins and (2) flex layout
.page-footer {
  @include responsive-padding(all);
  @include responsive-margin(left, -1); // 1
  background: $dark-gray;
  color: $white;
  font-size: 15px;
  line-height: 35px;

  ul {
    @include no-bullets;
  }

  a {
    @include hover-with-color;
    color: $white;
  }
}


// =============================================================================
// Footer Blocks
// -----------------------------------------------------------------------------

.region-page-footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & > .block {
    @include responsive-padding(left);
    margin-bottom: 30px;
    width: 100%;

    @include breakpoint(above, 'x-small') {
      margin-bottom: 50px;
      width: 50%;
    }

    @include breakpoint(above, 'large') {
      width: 25%;
    }

    & > .block__title {
      color: $white;
      font-size: 20px;
      font-weight: normal;
      line-height: 25px;
      text-transform: uppercase;
    }
  }
}


// Social Links
// -----------------------------------------------------------------------------
.page-footer__social-link {
  a::before {
    display: inline-block;
    font-family: $font-family--icon;
    margin-right: 1em;
  }

  &--facebook {
    a::before {
      content: 'f';
    }
  }

  &--linkedin {
    a::before {
      content: 'L';
    }
  }
}


// Contact links
// -----------------------------------------------------------------------------
.page-footer__contact {
  dt,
  dd {
    display: block;
    float: left;
  }

  dt {
    clear: both;
    margin-right: .5em;

    &::after {
      content: ': ';
    }
  }

  dd {
    display: inline-block;
    margin: 0;
  }
}


// Legal Info
// -----------------------------------------------------------------------------

.page-footer__legal {
  line-height: 23px;
}


// =============================================================================
// Bottom Row
// -----------------------------------------------------------------------------

.region-page-footer-bottom {
  @include responsive-padding(left);

  .menu__item {
    display: inline;

    &:not(:last-child) {
      &::after {
        content: '\2002|\2002'; // Add en-spaces around the pipe
      }
    }
  }
}
