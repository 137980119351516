$webform-column-gap: 20px;

$spacing--webform-component-gap: (
  'xx-small': 20px null,
);

$spacing--webform-component-padding: (
  'xx-small': null 20px,
);

// Needs to be 30px. Since we can't remove the spacing from the last webform
// component, we just adjust it here.
$spacing--webform-action-gap: (
  'xx-small': 10px null,
);

// -----------------------------------------------------------------------------

.node-type-webform {
  .webform-client-form {
    @include responsive-margin(x);
    @include responsive-margin(y);
  }
}

.webform-client-form {
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$webform-column-gap;
    max-width: 800px + $webform-column-gap;
  }
}

.form-item,
.form-actions {
  padding-left: $webform-column-gap;
  width: 100%;
}

.captcha {
  padding-left: $webform-column-gap;
  margin-bottom: 10px;
}

.form-item {
  @include responsive-margin(bottom, $scale: $spacing--webform-component-gap);
}

.form-item--1-2 {
  @include breakpoint(above, 'x-small') {
    width: 50%;
  }
}

.form-item--1-4 {
  @include breakpoint(above, 'x-small') {
    width: 25%;
  }
}

.form-actions {
  @include responsive-margin(top, $scale: $spacing--webform-action-gap);
}

// =============================================================================
// Webform Components
// -----------------------------------------------------------------------------

.webform-component--form-title h2 {
  @include h3-like;
}

.webform-component--form-intro {
  @include p-spacing($remove-last: false);
}

// =============================================================================
// Property Contact Form
// -----------------------------------------------------------------------------

.webform-client-form.webform-client-form-17 {
  justify-content: flex-start;
}
