$textfield-height: (
  'xx-small': 45px,
  'small': 50px,
);

$textarea-height: (
  'xx-small': 150px,
);

$textarea-line-height: 26px;

$spacing--filled-textfield-margin: (
  'xx-small': null null 40px,
);

$spacing--filled-error-textfield-margin: modify-spacing-scale(
  $spacing--filled-textfield-margin,
  28px
);

$spacing--webform-label: modify-spacing-scale(
  $spacing--webform-component-padding,
  $webform-column-gap,
  'add'
);

$input-label-line-height: (
  'xx-small': 18px,
  'small': 18px,
);

$input-label-scale: (
  'xx-small': calc(14 / 16),
  'small': calc(14 / 16),
);

$textfield-box-shadow-width: 1px;

// =============================================================================
// All Textfields
// -----------------------------------------------------------------------------

[type='text'],
[type='tel'],
[type='email'],
[type='password'],
textarea {
  @include responsive-padding(x, $scale: $spacing--webform-component-padding);
  background-color: $white;
  border: 1px solid $light-gray;
  box-shadow: inset 0 0 0 $textfield-box-shadow-width $white;
  display: block;
  font-size: 14px;
  height: map-get($textfield-height, 'xx-small');
  line-height: map-get($textfield-height, 'xx-small');
  outline: none;
  transition:
    border-color get-timing(normal) ease-in,
    box-shadow get-timing(normal) ease-in;
  width: 100%;

  @include breakpoint(above, 'small') {
    font-size: 16px;
    height: map-get($textfield-height, 'small');
    line-height: map-get($textfield-height, 'small');
  }

  &:focus {
    border-color: $accent;
    box-shadow: inset 0 0 0 $textfield-box-shadow-width $accent;
  }

  &.error {
    border-color: $error;
    box-shadow: inset 0 0 0 $textfield-box-shadow-width $error;
  }
}

.form-type-password,
.form-type-textfield,
.webform-component-textfield,
.webform-component-telephone,
.webform-component-email,
.webform-component-textarea {
  margin-bottom: 20px;
  position: relative;
  transition: margin-bottom get-timing(normal) ease-in;

  &.has-suggestion,
  &.has-error {
    @include responsive-margin(
      bottom,
      $scale: $spacing--filled-textfield-margin
    );
  }

  label {
    @include responsive-position(left, $scale: $spacing--webform-label);
    color: $light-gray;
    cursor: text;
    display: block;
    font-size: 16px;
    position: absolute;
    top: 0;
    transform:
      translateZ(0)
      translateY(map-get($textfield-height, 'xx-small') / 2)
      translateY(-50%);
    transform-origin: top left;
    transition:
      top get-timing(normal) ease-in-out,
      transform get-timing(normal) ease-in-out;

    @include breakpoint('above', 'small') {
      transform:
        translateZ(0)
        translateY(map-get($textfield-height, 'small') / 2)
        translateY(-50%);
    }
  }

  span.suggestion,
  span.error {
    @include responsive-position(left, $scale: $spacing--webform-label);
    font-size: 14px;
    line-height: map-get($input-label-line-height, 'xx-small');
    position: absolute;
    top: map-get($textfield-height, 'xx-small') + 10px;
    transition: transform get-timing(normal) ease-in-out;

    @include breakpoint('above', 'small') {
      top: map-get($textfield-height, 'small') + 10px;
    }
  }

  span.error {
    color: $error;
  }

  &.is-focused,
  &.is-filled {
    @include responsive-margin(
      bottom,
      $scale: $spacing--filled-textfield-margin
    );

    label {
      transform:
        translateZ(0)
        translateY(map-get($textfield-height, 'xx-small'))
        translateY(5px)
        scale(map-get($input-label-scale, 'xx-small'));

      @include breakpoint(above, 'small') {
        transform:
          translateZ(0)
          translateY(map-get($textfield-height, 'small'))
          translateY(5px)
          scale(map-get($input-label-scale, 'small'));
      }
    }

    &.has-error {
      @include responsive-margin(
        bottom,
        $scale: $spacing--filled-error-textfield-margin
      );

      span.error {
        transform:
          translateZ(0)
          translateY(map-get($input-label-line-height, 'xx-small'));
      }
    }

    &.has-suggestion {
      @include responsive-margin(
        bottom,
        $scale: $spacing--filled-error-textfield-margin
      );

      span.suggestion {
        transform:
          translateZ(0)
          translateY(map-get($input-label-line-height, 'xx-small'));
      }
    }
  }
}

// =============================================================================
// Suggestions
// -----------------------------------------------------------------------------

.suggestion__hint {
  border-bottom: 1px solid $black;
  color: $black;
  display: inline-block;
  font-weight: bold;
  transition-duration: get-timing(fast);
  transition-timing-function: ease-in-out;
  transition-property: border-bottom-color, color;

  &:hover,
  &:focus {
    border-bottom-color: $accent;
    color: $accent;
  }
}

.suggestion__domain {
  font-style: italic;
}

// =============================================================================
// Textareas
// -----------------------------------------------------------------------------

textarea {
  @include padding(
    y,
    (map-get($textfield-height, 'xx-small') - $textarea-line-height) / 2
  );
  height: map-get($textarea-height, 'xx-small');
  line-height: $textarea-line-height;
  resize: none;

  @include breakpoint('above', 'small') {
    @include padding(
      y,
      (map-get($textfield-height, 'small') - $textarea-line-height) / 2
    );
  }
}

.webform-component-textarea {
  span.error {
    top: map-get($textarea-height, 'xx-small') + 10px;
  }
}

div[id^="google_recaptcha_webform"] {
  margin-bottom: 20px;

  + .error--custom {
    color: $error;
    font-size: 14px;
    padding-left: 20px;
  }
}

.webform-component-textarea.is-focused,
.webform-component-textarea.is-filled {
  label {
    transform:
      translateZ(0)
      translateY(map-get($textarea-height, 'xx-small'))
      translateY(5px)
      scale(map-get($input-label-scale, 'small'));
  }
}


// =============================================================================
// Property Contact Forms
// -----------------------------------------------------------------------------

.webform-client-form-17 {
  [type='text'],
  [type='tel'],
  [type='email'],
  [type='password'],
  textarea {
    border-color: $dark-gray;

    &:focus {
      border-color: $accent;
    }

    &.error {
      border-color: $error;
    }
  }

  .suggestion {
    color: $off-white;
  }

  .suggestion__hint {
    color: $white;
    border-bottom-color: $white;

    &:hover,
    &:focus {
      color: $accent;
      border-bottom-color: $accent;
    }
  }
}
