// =============================================================================
// Wrapper
// -----------------------------------------------------------------------------

.view-news {
  @include container(800px, '.view-content');
  @include responsive-margin(y);
}

.news-item-list {
  @include no-bullets;
  margin: 0;
}


// =============================================================================
// News Items
// -----------------------------------------------------------------------------

.news-item {
  &:not(:last-child) {
    margin-bottom: 60px;
  }
}

.news-item__timestamp {
  color: $light-gray;
  display: block;
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 10px;
}

.news-item__link {
  @include hover-with-color;
  color: inherit;
  font-size: 25px;
  font-weight: 500;
}
