.property__content {
  @include responsive-margin(left, -1);

  @include breakpoint('above', 'large') {
    display: flex;
    flex-wrap: none;
  }

  & > * {
    @include responsive-padding(left);

    @include breakpoint('above', 'large') {
      flex-shrink: 0;
      width: 50%;
    }
  }

  & .field-name-body {
    @include margin(y, 50px);
  }
}
