$option-indicator-diameter: 20px;

$option-indicator-inner-diameter: 14px;

$option-indicator-inner-offset:
  ($option-indicator-diameter - $option-indicator-inner-diameter) / 2;


// =============================================================================
// Radio Buttons & Checkboxes
// -----------------------------------------------------------------------------

.webform-component-radios,
.webform-component-checkboxes {
  span.error {
    color: $error;
    display: block;
    font-size: 14px;
  }
}

// Group
.form-radios,
.form-checkboxes {
  margin-bottom: 30px;
  margin-top: 20px;
}

// Individual checkbox items need to have the column padding removed, since it's
// already added on the group.
.form-type-radio,
.form-type-checkbox {
  padding-left: 0;
}

[type='radio'],
[type='checkbox'] {
  @include hide-visually;

  & + label {
    @include responsive-margin(left, $scale: $spacing--webform-component-padding);
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    line-height: $option-indicator-diameter;
    margin-bottom: 20px;
    position: relative;

    &::before {
      @include circle($option-indicator-diameter);
      border: 1px solid $dark-gray;
      content: '';
      display: inline-block;
      flex-shrink: 0;
      margin-right: $webform-column-gap;
      transition: border-color get-timing(fast) ease-in-out;
    }

    &::after {
      @include circle($option-indicator-inner-diameter);
      @include position(
        absolute,
        $option-indicator-inner-offset null null $option-indicator-inner-offset
      );
      background-color: $accent;
      content: '';
      display: block;
      opacity: 0;
      transform: scale(0);
      transition:
        opacity get-timing(fast) ease-in-out,
        transform get-timing(fast) ease-in-out;
    }

    &:hover,
    &:focus {
      &::after {
        opacity: .5;
        transform: scale(.75);
      }
    }
  }

  &:checked + label {
    &::before {
      // border-color: $accent;
    }

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }
}
