// =============================================================================
// Group
// -----------------------------------------------------------------------------

.paragraphs-item-thumbnail-column-group {
  @include responsive-padding(x);
}

.field-name-thumbnail-columns > .field-items {
  $thumbnail-column-gap: 5px;
  @include no-bullets;
  margin: 0;

  @include breakpoint(above, 'medium') {
    display: flex;
    margin-left: -$thumbnail-column-gap;
  }

  & > .field-item {
    padding-left: $thumbnail-column-gap;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    @include breakpoint(above, 'medium') {
      margin-bottom: 0;
    }
  }
}

// =============================================================================
// Individual
// -----------------------------------------------------------------------------

.thumbnail-column {
  @include breakpoint(between, 'x-small', 'medium') {
    display: flex;
  }

  .field-name-thumbnail {
    @include thumbnail;
    height: 200px;
    margin-bottom: 20px;
    position: relative;

    @include breakpoint(above, 'x-small') {
      @include aspect-ratio(1, 1);
      height: 100%;
      margin-bottom: 0;
      width: 50%;
    }

    @include breakpoint(above, 'medium') {
      width: 100%;
    }
  }
}

.thumbnail-column__body {
  @include breakpoint(above, 'x-small') {
    padding-left: 30px;
    width: 50%;
  }

  @include breakpoint(above, 'medium') {
    padding-left: 20px;
    width: 100%;
  }

  .field-name-paragraph-title {
    color: $accent;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25;

    @include breakpoint(above, medium) {
      $title-line-height: 18px;
      $title-padding-bottom: 20px;
      margin-bottom: -$title-line-height;
      padding-bottom: $title-padding-bottom;
      position: relative;
      top: -1 * ($title-line-height + $title-padding-bottom);
    }

    @include breakpoint(above, 'xx-large') {
      font-size: 20px;
    }
  }

  .field-name-text {
    margin-bottom: 20px;
  }

  .field-name-cta-button {
    margin-top: 40px;
  }

  .field-name-link-list > .field-items {
    @include no-bullets;
    color: $light-gray;
    line-height: 35px;
    margin: 0;

    a {
      @include hover-with-underline;
      display: inline-block;
    }
  }
}


// =============================================================================
// Front Page
// -----------------------------------------------------------------------------

.front {
  .paragraphs-item-thumbnail-column-group {
    &::after {
      @include responsive-margin(y);
      border-bottom: 1px solid $light-gray;
      content: '';
      display: block;
    }
  }
}
