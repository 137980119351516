.field-name-breadcrumbs {
  @include responsive-margin(top, -.5);
  margin-bottom: 35px;
  width: 100%;

  @include breakpoint('below', 'large') {
    @include hide-visually;
  }
}

.breadcrumbs__item-list {
  @include no-bullets;
  margin: 0;
}

.breadcrumbs__item {
  display: inline;
  font-size: 14px;

  &:not(:last-child) {
    &::after {
      color: $light-gray;
      content: ' / ';
    }
  }
}

.breadcrumbs__leaf {
  color: $light-gray;
}

.breadcrumbs__leaf--link {
  @include hover-with-color;
}

.breadcrumbs__leaf--current-page {
  cursor: default;
}
