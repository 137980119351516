// =============================================================================
// Group
// -----------------------------------------------------------------------------

.paragraphs-item-tile-group {
  .field-name-paragraphs {
    @include no-bullets;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 -1px;
  }
}


// =============================================================================
// Individual
// -----------------------------------------------------------------------------

.tile {
  @include tile;
  height: 200px;
  position: relative;

  @include breakpoint(above, 'x-small') {
    height: 230px;
  }

  @include breakpoint(above, 'small') {
    height: 250px;
  }

  @include breakpoint(above, 'large') {
  }

  @include breakpoint(above, 'xx-large') {
    height: 300px;
  }

  @include breakpoint(above, 'xxx-large') {
    height: 400px;
  }

  .field-name-thumbnail {
    @include thumbnail;
    @include rectangle(100%, 100%);
  }

  .link {
    @include tile-inner;
    @include position(absolute, 0 0 0 1px);

    &:hover,
    &:focus {
      .link__text::before {
        opacity: 1;
      }
    }
  }

  .link__text {
    @include z-index('tile-overlay', +1);
    color: $white;

    &::before {
      @include tile-arrow;
    }
  }
}

.tile--wide {
  @include breakpoint(above, 'large') {
    width: percentage(1 / 2);
  }
}
