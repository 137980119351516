/// The widest the site can be
/// @type Number
/// @group Layout
$max-site-width: 1920px;

/// The narrowest the site can be
/// @type Number
/// @group Layout
$min-site-width: 320px;


// =============================================================================


/// The boundaries for breakpoints
/// @type Map
/// @group Layout

$breakpoints: (
  'xx-small':   320px,
  'x-small':    480px,
  'small':      600px,
  'medium':     800px,
  'large':     1000px,
  'x-large':   1200px,
  'xx-large':  1400px,
  'xxx-large': 1600px,
);


// =============================================================================


/// Default spacing for containers
/// @type Map
/// @group Layout

$spacing-default: (
  'xx-small':  40px   25px,
  'x-small':   40px   30px,
  'small':     50px   40px,
  'medium':    55px   40px,
  'large':     75px   55px,
  'x-large':   75px   55px,
  'xx-large':  100px  80px,
  'xxx-large': 130px 150px,
);


// =============================================================================


/// Default spacing between columns
/// @type Map
/// @group Layout
$spacing-column: (
  'xx-small':  null 20px,
  'x-small':   null 20px,
  'small':     null 30px,
  'medium':    null 35px,
  'large':     null 40px,
  'x-large':   null 50px,
  'xx-large':  null 90px,
  'xxx-large':  null 100px,
);


/// Default spacing for content sections with drop caps
/// @type Map
/// @group Layout
$spacing--drop-cap: (
  'xx-small': null 0,
  'x-small': null 0,
  'small': null 60px,
  'medium': null 100px,
  'large': null 100px,
  'x-large': null 100px,
  'xx-large': null 140px,
  'xxx-large': null 140px,
);
