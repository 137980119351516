.drop-cap__inner {
  @include responsive-margin(left, $scale: $spacing--drop-cap);
  position: relative;
}

.drop-cap__letter {
  margin-bottom: 1ex;
  position: relative;

  @include breakpoint(above, 'small') {
    @include position(absolute, 0 100% null null);
  }

  // ===========================================================================
  // Letters
  // ---------------------------------------------------------------------------

  &::before,
  &::after {
    @include z-index('drop-cap');
    content: attr(data-letter);
    display: block;
    font-weight: 700;
    line-height: 1.5ex;
    pointer-events: none;
    text-transform: uppercase;
  }

  // Big Letter
  // ---------------------------------------------------------------------------
  // 1: The dropcaps are mostly used on dark backgrounds, so to make sure we
  //    only see the outline, we need to make sure the actual text matches the
  //    background color. If we set this to transparent, the `text-shadow` will
  //    show through.
  //
  // 3: Ensure the dropcap doesn't cover up other content
  //
  //
  &::before {
    @include text-stroke(1px, $accent);
    @include responsive-margin(bottom);
    @include responsive-position(
      left, -.5,
      $scale: $spacing--drop-cap,
      $from: 'small'
    );
    color: $dark-gray; // 1
    font-size: 350px;
    opacity: .3;
    position: absolute;
    transform: translate3d(0, -25%, 0);
    z-index: 0; // 3


    @include breakpoint(above, 'medium') {
      font-size: 450px;
    }

    @include breakpoint(above, 'xx-large') {
      font-size: 620px;
    }
  }

  // Small Letter
  // ---------------------------------------------------------------------------
  &::after {
    color: $accent;
    font-size: 100px;
    position: relative;

    @include breakpoint(above, 'small') {
      position: absolute;
      right: calc(100% + 10px);
    }

    @include breakpoint(above, 'medium') {
      font-size: 150px;
    }

    @include breakpoint(above, 'xx-large') {
      font-size: 200px;
    }
  }
}


// ===========================================================================
// Drop Cap Copy
// ---------------------------------------------------------------------------
// We need to ensure that the text following the drop cap is rendered above
// the drop cap itself.
.drop-cap,
.drop-cap__letter {
  & ~ * {
    @include z-index('drop-cap', +1);
    position: relative;
  }
}


// =============================================================================
// Property Pages
// -----------------------------------------------------------------------------

// 1: The drop caps on property pages appear on a white background, so we need
//    to update the text color accordingly to match the background.
.node-type-property {
  .drop-cap__letter::before {
    color: $white; // 1
  }
}
