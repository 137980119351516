.paragraphs-item-list-illustrated {
  @include container(800px);

  > .content {
    @include breakpoint('above', 'medium') {
      display: flex;
      flex-direction: row;
    }
  }

  .illustrated-list__header {
    align-items: center;
    display: flex;
    margin-bottom: 30px;

    @include breakpoint('above', 'medium') {
      flex-direction: column;
      margin-bottom: 0;
      margin-right: 50px;
      text-align: center;
      width: 100px;
    }
  }

  .field-name-paragraph-title {
    @include margin(y, 0);
    color: $accent;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    max-width: 100px;
    text-transform: uppercase;

    @include breakpoint('above', 'medium') {
      max-width: none;
      text-align: center;
    }
  }

  .field-name-list-illustration {
    display: flex;
    justify-content: center;
    margin-right: 15px;

    @include breakpoint('above', 'medium') {
      margin-bottom: 30px;
      margin-right: 0;
    }

    svg,
    img {
      display: block;
      height: 65px;
      width: auto;

      @include breakpoint('above', 'medium') {
        height: auto;
      }
    }

    path {
      fill: $accent;
    }
  }

  .field-name-list-items .field-items {
    @include checked-list;
  }
}
