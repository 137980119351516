@mixin checked-list($icon-margin: 8px) {
  @include no-bullets;
  @include margin(y, 0);

  > * {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    &::before {
      color: $accent;
      content: '✔';
      flex-shrink: 0;
      font-family: $font-family--icon;
      font-size: 15px;
      margin-right: $icon-margin;
      text-align: left;
      text-indent: 0;
    }
  }
}
