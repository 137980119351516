.property__actions {
  width: 100%;
}

.property-icon-list {
  @include no-bullets;
  @include responsive-margin(left, -1);
  @include responsive-margin(top);

  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -40px;

  @include breakpoint('between', 'medium', 'xx-large') {
    flex-wrap: nowrap;
  }

  @include breakpoint('above', 'xx-large') {
    margin-bottom: -60px;
  }
}

.property-icon {
  @include responsive-padding(left);

  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  text-align: center;
  width: percentage(1 / 2);

  @include breakpoint('above', 'x-small') {
    width: percentage(1 / 3);
  }

  @include breakpoint('above', 'medium') {
    width: percentage(1 / 6);
  }

  @include breakpoint('above', 'xx-large') {
    margin-bottom: 60px;
  }

  a {
    @include hover-with-color;
    color: $light-gray;
    display: block;

    &.is-open {
      color: $white;
    }
  }

  &--pet-friendly {
    display: block;

    /// @hack
    /// On IE & Edge, the image gets incorrectly replaced by the object-fit-image
    /// polyfill used for e.g. the hero images. Rather than fix the polyfill,
    /// we're just going to use a nasty @supports / !important hack.
    @supports not (object-fit: cover) {
      img {
        display: block !important;
      }

      .object-fit-image {
        display: none;
      }
    }
  }
}

.property-icon__related-items {
  @include hide-visually;
}

.property-icon__icon {
  display: block;
  font-size: 36px;
  padding-bottom: 15px;
}

.property-icon__label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
}
