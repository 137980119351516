.paragraphs-item-intro {
  display: flex;
  justify-content: center;
  text-align: center;

  & > .content {
    @include responsive-margin(x);
    max-width: 900px;
  }

  .field-name-paragraph-title {
    @include h1-like;
  }

  .field-name-text {
    color: $light-gray;
    font-size: 19px;
    line-height: 29px;

    @include breakpoint(above, 'x-small') {
      font-size: 20px;
      line-height: 30px;
    }

    @include breakpoint(above, 'large') {
      font-size: 23px;
      line-height: 33px;
    }

    @include breakpoint(above, 'xxx-large') {
      font-size: 25px;
      line-height: 35px;
    }
  }
}
