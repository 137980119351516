.view-testimonials {
  @include responsive-margin(top);
  @include responsive-padding(x);
}

.testimonial-list {
  @include no-bullets;
  @include responsive-margin(left, -1, $to: 'large');
  @include responsive-margin(left, -.5, $from: 'large');
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.testimonial-list--testimonial {
  @include responsive-padding(left, $to: 'large');
  @include responsive-padding(left, .5, $from: 'large');
  @include responsive-padding(bottom, $to: 'small');
  @include responsive-padding(bottom, .5, $from: 'small');
  display: inline-block;
  width: percentage(1 / 1);

  @include breakpoint('above', 'medium') {
    width: percentage(1 / 2);
  }

  @include breakpoint('above', 'x-large') {
    width: percentage(1 / 3);
  }
}

.node-testimonial {
  background: $white;
  border: 1px solid $gray;
  color: $gray;
  padding: 30px 20px;

  @include breakpoint('above', 'x-small') {
    padding: 40px 30px;
  }

  .page__title {
    margin-bottom: 20px;
  }

  .field-name-logo {
    text-align: center;
    margin-bottom: 20px;
  }

  .field-name-text {
    &::after {
      border-bottom: 1px solid $off-white;
      content: '';
      display: block;
      margin: 20px 0;
      width: 50px;
    }
  }

  .field-name-authors {
    margin-top: 20px;

    .field-items {
      @include no-bullets;
      margin: 0;

      .field-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .double-field-first {
      font-weight: 500;
    }
  }

  .field-name-other-text {
    color: $gray;
    font-style: italic;
    font-weight: 300;
    margin-top: 10px;
  }
}
