////
/// @file
/// Main paragraph collections, e.g. as used in the body field on basic pages
////

.field-name-paragraphs {
  > .field-items {
    @include no-bullets;
    margin: 0;
  }

  > .field-items > .field-item,
  > .field-item {
    @include responsive-margin(y);
  }
}


// =============================================================================
// Front Page
// -----------------------------------------------------------------------------

.front {
  .node-page > .paragraphs-items > .field-name-paragraphs {
    @include breakpoint(above, 'large') {
      background-image: url(../../images/front-background.png);
      background-position: top center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    & > .field-items > .field-item {
      &:first-child {
        @include responsive-padding(top);
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


// =============================================================================
// Property Landing Pages
// -----------------------------------------------------------------------------

// 1: The margin on these pages comes from the Available Properties view, rather
//    than the bottom of the content. This is done because the sold properties
//    page is a block that appears after the content, rather than an Entity View
//    Attachment, like it is on the other property landing pages. Because it's a
//    block, it can't rely on normal content spacing.
.node-property-landing-page {
  .field-name-paragraphs > {
    > .field-items > .field-item,
    > .field-item {
      margin-bottom: 0; // 1
    }
  }
}


// =============================================================================
// Thank You Page
// -----------------------------------------------------------------------------

.node-thank-you-page {
  .field-name-paragraphs > {
    > .field-items > .field-item,
    > .field-item {
      @include margin(y, 0);
    }
  }
}


// =============================================================================
// Maintenance Request Webform
// -----------------------------------------------------------------------------

.node-11 {
  .field-name-paragraphs {
    @include dark-background;
  }
}
