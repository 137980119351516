// 1: Ensure page headers are always above any drop caps
.page__header {
  @include responsive-padding(x);
  @include responsive-padding(top);
  display: flex;
  flex-direction: column;

  @include breakpoint('above', 'medium') {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  > * {
    @include z-index('drop-cap', +1); // 1
    position: relative;
  }

  .scroll-to-form {
    margin-top: 20px;

    @include breakpoint('above', 'medium') {
      margin-top: 0;
    }
  }
}


// =============================================================================
// Dark Backgrounds
// -----------------------------------------------------------------------------

.node-11, // Maintenance Request Page
.node-44  // About Page
{
  .page__header {
    @include dark-background;
  }
}


// =============================================================================
// Narrow Headers
// -----------------------------------------------------------------------------

.node-7,   // Contact Us Page
.node-42,  // News Landing Page
.node-43,  // Pay Rent Online Page
.node-1952 // Privacy Policy
{
  .page__header {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }
}


// =============================================================================
// Contact Form
// -----------------------------------------------------------------------------
// @see https://altosagency.teamwork.com/tasks/5842442

.node-7 {
  .page__header {
    @include breakpoint('above', 'x-small') {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .scroll-to-form {
      @include breakpoint('above', 'x-small') {
        margin-top: 0;
      }
    }
  }
}
