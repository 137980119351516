@mixin h1-like {
  color: $accent;
  font-size: 30px;
  font-weight: 300;
  line-height: 35px;

  @include breakpoint(above, 'x-small') {
    font-size: 35px;
    line-height: 40px;
  }

  @include breakpoint(above, 'medium') {
    font-size: 40px;
    line-height: 45px;
  }

  @include breakpoint(above, 'large') {
    font-size: 45px;
    line-height: 50px;
  }

  @include breakpoint(above, 'xx-large') {
    font-size: 50px;
    line-height: 55px;
  }

  @include breakpoint(above, 'xxx-large') {
    font-size: 50px;
    line-height: 55px;
  }
}


@mixin h2-like {
  color: $accent;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;

  @include breakpoint(above, 'x-small') {
    font-size: 25px;
    line-height: 30px;
  }
}


@mixin h3-like {
  color: $dark-gray;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;

  @include breakpoint(above, 'x-small') {
    font-size: 20px;
    line-height: 25px;
  }
}


@mixin body-text-font-sizing {
  font-size: 17px;
  line-height: 27px;

  @include breakpoint(above, x-small) {
    font-size: 18px;
    line-height: 28px;
  }
}


@mixin body-text {
  @include body-text-font-sizing;
  color: $gray;
}


@mixin p-spacing($property: 'margin', $scale: 1, $remove-last: true) {
  #{$property}-bottom: 26px * $scale;
  #{$property}-top: 0;

  @include breakpoint(above, small) {
    #{$property}-bottom: 27px * $scale;
  }

  @include breakpoint(above, xx-large) {
    #{$property}-bottom: 28px * $scale;
  }

  @if ($remove-last) {
    &:last-child {
      #{$property}-bottom: 0;
    }
  }
}

@mixin p-spacing-top {
  margin-top: 26px;

  @include breakpoint(above, small) {
    margin-top: 27px;
  }

  @include breakpoint(above, xx-large) {
    margin-top: 28px;
  }
}

@mixin disclaimer-text {
  color: $light-gray;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}
