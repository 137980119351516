.node-property-manager {
  margin-top: 40px;
  color: $white;

  a {
    @include hover-with-color;
    color: inherit;
  }

  .page__header {
    margin: 0;
    padding: 0;
  }

  .page__title {
    @include body-text-font-sizing;
    color: $white;
    font-weight: 500;
  }

  .property-manager__contact-info {
    h3 {
      @include hide-visually;
    }

    // The email addresses can get pretty long, and tend to overflow the form
    // container just before going to the tablet layout. We can't line wrap the
    // email addresses, so instead we clip it with an ellipsis.
    .field-name-email .field-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
