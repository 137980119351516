$drupal-message-colors: (
  'status': $accent,
  'warning': $warning,
  'error': $error,
);

.messages {
  @include responsive-padding(x);
  @include padding(y, 20px);
  border: 0;
  display: flex;
  font-weight: bold;
  justify-content: center;
  position: relative;
  width: 100%;
}

.messages__content {
  display: flex;
  max-width: 800px;
  width: 100%;
}

.messages__icon {
  @include square(40px);
  align-items: center;
  background: $white;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 20px;
}

.messages__text {
  align-items: center;
  display: flex;
}

.messages__list {
  @include margin(y, 0);
  max-width: 800px;
  width: 100%;
}

@each $type, $color in $drupal-message-colors {
  .messages--#{$type} {
    background-color: $color;
    color: $white;

    .messages__icon path {
      fill: $color;
    }
  }
}
