.drawer {
  position: relative;
}

.drawer__pointer {
  @include triangle(36px, 18px, $white, up);
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition-duration: get-timing(normal);
  transition-property: transform, left;

  .drawer.is-open & {
    transform: translate(-50%, -100%);
  }
}

.drawer__close-button {
  @include responsive-position(top);
  @include responsive-position(right);
  @include hover-with-color;
  appearance: none;
  background: $transparent;
  border: 0;
  color: $light-gray;
  cursor: pointer;
  display: block;
  font-size: 72px;
  opacity: 0;
  outline: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity get-timing(normal);

  .drawer.is-open & {
    opacity: 1;
    pointer-events: auto;
  }
}

.drawer__contents {
  display: flex;
  justify-content: center;
  padding-right: 100px;

  > div {
    @include responsive-padding(y);
    @include responsive-margin(x);
    max-width: 800px;
    width: 100%;
  }
}

// =============================================================================
// Drawer Contents
// -----------------------------------------------------------------------------

.field-name-application-list .field-items,
.file-list {
  @include no-bullets;
  @include margin(y, 0);
  display: flex;
  flex-wrap: wrap;

  li {
    font-size: 16px;
    margin-bottom: 30px;
  }

  a {
    @include hover-with-color;
    color: $light-gray;
    display: inline-flex;
  }
}

// =============================================================================
// Application List
// -----------------------------------------------------------------------------

.field-name-application-list {
  .field-item {
    width: 100%;
  }
}

// =============================================================================
// Floor Plan & Site Plan Lists
// -----------------------------------------------------------------------------

.file-list {
  @include responsive-margin(left, -1, $to: 'xxx-large');

  a {
    &::before {
      @include rectangle(24px, 30px);
      background-size: contain;
      content: '';
      display: block;
      flex-shrink: 0;
      margin-right: 20px;
    }
  }

  .file-list__file {
    @include responsive-padding(left, $to: 'xxx-large');
    width: percentage(1 / 1);

    @include breakpoint('above', 'small') {
      width: percentage(1 / 2);
    }

    @include breakpoint('above', 'large') {
      width: percentage(1 / 4);
    }

    &--pdf {
      a::before {
        background-image: url(../../images/files/pdf.svg);
      }
    }
  }
}
