
.hide-visually,
.visually-hidden,
.element-invisible {
  @include hide-visually;
}

.visible-above-x-large {
  @include hide-visually;

  @include breakpoint(above, x-large) {
    @include unhide-visually;
  }
}

.normal {
  font-style: normal !important;
  font-weight: normal !important;
}

.clearfix {
  @include clearfix;
}

.overflow-x {
  overflow-x: auto;
  width: 100%;
}

// =============================================================================
// Utility Classes
// -----------------------------------------------------------------------------

.margin-y {
  @include responsive-margin(y);
}

.margin-top {
  @include responsive-margin(top);
}

.margin-bottom {
  @include responsive-margin(bottom);
}

.margin-x {
  @include responsive-margin(x);
}

.margin-left {
  @include responsive-margin(left);
}

.margin-right {
  @include responsive-margin(right);
}


.padding-y {
  @include responsive-padding(y);
}

.padding-top {
  @include responsive-padding(top);
}

.padding-bottom {
  @include responsive-padding(bottom);
}

.padding-x {
  @include responsive-padding(x);
}

.padding-left {
  @include responsive-padding(left);
}

.padding-right {
  @include responsive-padding(right);
}

// =============================================================================
// Browser / OS bug fixes
// -----------------------------------------------------------------------------

// Disable rounded text inputs on iOS
[type='text'],
[type='search'],
[type='email'],
[type='number'],
textarea {
  -webkit-appearance: none;
}

// OS X subpixel anti-aliasing breaks when using light text on dark backgrounds.
// In our footer, this causes the semi-transparent text to look really thin and
// washed out in Safari.
//
// @see http://www.lighterra.com/articles/macosxtextaabug/
// @see https://altosagency.teamwork.com/tasks/4860318
#page-footer,
.menu-name-menu-hero,
.hero__text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// =============================================================================
// Placeholder Content
// -----------------------------------------------------------------------------
// Add a pink border around placeholder content so that it's more noticeable
// when going through the site

$placeholder-color: #f0f;

.lipsum,
%lipsum {
  outline: 1px solid $placeholder-color;
}
