@mixin container($width, $content: '> .content') {
  @include responsive-margin(x);
  align-items: center;
  display: flex;
  flex-direction: column;

  #{$content} {
    max-width: $width;
    width: 100%;
  }
}
