.menu-name-menu-hero {
  @include z-index('hero', +1);
  @include position(absolute, null null null 50%);
  @include responsive-position(bottom, $to: 'small');
  text-align: center;
  transform: translateX(-50%);

  @include breakpoint(above, 'small') {
    bottom: 130px;
    text-align: left;
  }

  .menu__tree {
    @include no-bullets;
    margin: 0;
  }


  // =============================================================================
  // First Level
  // -----------------------------------------------------------------------------

  .menu__tree--level-1 {
    @include breakpoint('above', 'small') {
      align-items: flex-end;
      display: flex;
      margin-left: -100px;
    }

    @include breakpoint('above', 'medium') {
      margin-left: -150px;
    }
  }

  .menu__item--level-1 {
    font-weight: 500;
    line-height: 35px;
    transition: transform get-timing(normal) ease-in-out;

    @include breakpoint('above', 'small') {
      padding-left: 100px;
    }

    @include breakpoint('above', 'medium') {
      padding-left: 150px;
    }

    & > .menu__link {
      @include hover-with-color;
      color: $white;
      cursor: pointer;
    }

    &.is-open > .menu__link {
      color: $accent;
    }
  }


  // =============================================================================
  // Second Level
  // -----------------------------------------------------------------------------

  .menu__submenu--level-2 {
    position: relative;
  }

  .menu__tree--level-2 {
    overflow: visible;

    @include breakpoint(above, 'small') {
      position: absolute;
    }
  }

  .menu__item--level-2 {
    font-weight: 300;
    height: 0;
    line-height: 35px;
    overflow: hidden;
    transition: height get-timing(normal);

    & > .menu__link {
      @include hover-with-underline;
      color: $white;
      white-space: nowrap;
    }
  }

  .menu__item--level-1.is-open {
    @include breakpoint(above, 'small') {
      transform: translate3d(0, -35px, 0);
    }

    .menu__item--level-2 {
      height: 35px;
    }
  }
}
