.content-wrapper {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint(above, large) {
    flex-direction: row;
  }
}


.content-area {
  @include responsive-padding(x);
  @include responsive-padding(top);
  @include responsive-margin(bottom);
  order: 0;

  @include breakpoint(above, large) {
    flex-grow: 1;
    order: 1;
  }
}


// =============================================================================
// Special Layouts
// -----------------------------------------------------------------------------

.content-wrapper--1-col {
  .content-area {
    width: percentage(1 / 1);
  }
}

.content-wrapper--2-col {
  @include breakpoint(above, large) {
    .content-area {
      width: percentage(2 / 3);
    }

    .sidebar {
      width: percentage(1 / 3);
    }
  }
}

.content-wrapper--3-col {
  .content-area,
  .sidebar {
    @include responsive-padding(x, $to: x-large);
  }

  @include breakpoint(above, large) {
    .content-area,
    .sidebar {
      width: percentage(1 / 3);
    }
  }

  @include breakpoint(above, x-large) {
    .content-area {
      width: percentage(2 / 4);
    }

    .sidebar {
      width: percentage(1 / 4);
    }
  }
}

.region-hidden {
  display: none;
}
