.hamburger {
  @include position(absolute, 0 null null 100%);
  background-color: $transparent;
  cursor: pointer;
  display: block;
  outline: 0;
  transition: background-color get-timing(fast);

  &:not(.is-open) {
    &:hover,
    &:focus {
      .hamburger__line {
        width: 100%;
      }
    }
  }

  &.is-open {
    background-color: $accent;

    &:hover,
    &:focus {
      background-color: $accent-hover;
    }
  }
}

.hamburger__inner {
  @include square(60px);
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;

  @include breakpoint(above, 'x-small') {
    @include square(75px);
    padding: 25px 20px;
  }

  @include breakpoint(above, 'large') {
    @include square(90px);
    padding: 30px 25px;
  }
}

.hamburger__line {
  background-color: $white;
  display: block;
  height: 3px;
  transition: all get-timing(normal) ease-in-out;
  width: 20px;

  @include breakpoint(above, 'x-small') {
    width: 25px;
  }

  @include breakpoint(above, 'large') {
    width: 30px;
  }


  &--middle {
    width: 100%;
  }
}

.menu-panel.is-open {
  // @HACK
  //       \
  //        \\
  //         \\
  //          >\/7
  //      _.-(6'  \
  //     (=___._/` \          .  .       __     __
  //          )  \ |          |\/|  /\  / _` | /  `
  //         /   / |          |  | /~~\ \__> | \__,
  //        /    > /                        __   ___  __   __    /
  //       j    < _\        |\ | |  |  |\/| |__) |__  |__) /__`  /
  //   _.-' :      ``.      | \| \__/  |  | |__) |___ |  \ .__/ .
  //   \ r=._\        `.
  //  <`\\_  \         .`-.
  //   \ r-7  `-. ._  ' .  `\
  //    \`,      `-.`7  7)   )
  //     \/         \|  \'  / `-._
  //                ||    .'
  //                 \\  (
  //                  >\  >
  //              ,.-' >.'
  //             <.'_.''
  //               <'
  & .hamburger__line {
    &--top {
      transform: translate(25%, 250%) rotate(-135deg);

      @include breakpoint(above, 'x-small') {
        transform: translate(25%, 400%) rotate(-135deg);
      }

      @include breakpoint(above, 'large') {
        transform: translate(25%, 500%) rotate(-135deg);
      }
    }

    &--middle {
      opacity: 0;
      transform: translateX(-100%);
    }

    &--bottom {
      transform: translate(25%, -325%) rotate(135deg);

      @include breakpoint(above, 'x-small') {
        transform: translate(25%, -350%) rotate(135deg);
      }

      @include breakpoint(above, 'large') {
        transform: translate(25%, -400%) rotate(135deg);
      }
    }
  }
}
