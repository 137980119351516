$table-border: 1px solid $gray;


// -----------------------------------------------------------------------------


table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 10px 0;
  text-align: left;
}

th + th,
td + td {
  padding-left: 20px;
}

th {
  border-bottom: $table-border;
  white-space: nowrap;
}

tfoot {
  td {
    border-top: $table-border;
  }
}
