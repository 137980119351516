.hero__scroll-banner {
  @include position(absolute, null 0 0);
  align-items: center;
  border-bottom: 7px solid $accent;
  display: flex;
  flex-wrap: wrap;
  transform: translate3d(0, 10px, 0);
  transition: transform get-timing(normal) ease-in-out;
  width: 100%;

  &:hover,
  &:focus {
    transform: none;
  }
}

.scroll-banner__text {
  @include hide-visually;
}

.scroll-banner__section {
  display: flex;
  width: 50%;

  &::before,
  &::after {
    background-color: $accent;
    content: '';
    display: block;
    flex-grow: 1;
    width: 50%;
  }

  &--left::after,
  &--right::before {
    background-color: $transparent;
    flex-grow: 0;
    flex-shrink: 0;
    width: auto;
  }

  &--left::after {
    @include triangle(7px, 7px, $accent, 'down-left');
  }

  &--right::before {
    @include triangle(7px, 7px, $accent, 'down-right');
  }
}

.scroll-banner__border {
  background-color: $accent;
  height: 10px;
  width: 100%;
}
