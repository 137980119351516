////
/// Variables related to typography
/// @group Typography
////

// =============================================================================
// Font families
// -----------------------------------------------------------------------------

/// Font family used for display text (e.g. titles, buttons)
/// @type list
$font-family--main: 'Roboto', 'Helvetica', 'Arial', sans-serif;

/// Font family to use for icon fonts
$font-family--icon: icomoon;


// =============================================================================
// Characters
// -----------------------------------------------------------------------------

/// CSS character entities, for use with e.g. icon fonts
/// @type map
$characters: (
  'bull': '2202',
  'copy': '00a9',
  'hellip': '2026',
  'ldquo': '201C',
  'rdquo': '201D',
  'lsquo': '2018',
  'rsquo': '2019',
  'middot': '00b7',
  'nbsp': '00a0',
  'zwj': '200c',

  // icomoon
  'chevron-up': '2191',
  'chevron-down': '2193',
  'chevron-left': '2190',
  'chevron-right': '2192',
  'zoom-in': '1f50d',
);
