html,
body {
  overflow-x: auto;
}

html {
  background-color: $page-background;
  display: flex;
  justify-content: center;
  min-width: $min-site-width;
}

body {
  background-color: $white;
  font-family: $font-family--main;
  max-width: $max-site-width;
  min-width: $min-site-width;
  position: relative;
}

iframe {
  border: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#page {
  overflow-x: hidden;
}

hr {
  @include responsive-margin(y, .5);
  background-color: $off-white;
  border: 0;
  height: 1px;
}
