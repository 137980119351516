// 1
@mixin dark-background {
  background-color: $dark-gray;
  color: $white;

  // OS X subpixel anti-aliasing breaks when using light text on dark
  // backgrounds, so we need to explicitly set these properties.
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  a:not([class]),
  a[class=''] {
    color: $white;
  }
}
