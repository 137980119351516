$masthead-logo-width: 458px;
$masthead-logo-height: 62px;

$masthead-logo-interior-scale: 30px / $masthead-logo-height;

$masthead-logo-height--interior:
  $masthead-logo-height * $masthead-logo-interior-scale;

$masthead-logo-width--interior:
  $masthead-logo-width * $masthead-logo-interior-scale;

.masthead__logo {
  @include position(absolute, null null null 80px);
  @include z-index('hero', +1);
  display: inline-block;
  height: 30px;
  margin-top: 20px;
  max-width: 100%;
  width: auto;

  @include breakpoint(above, 'x-small') {
    height: 35px;
    left: 105px;
    margin-top: 25px;
  }

  @include breakpoint(above, 'large') {
    left: 110px;
  }

  @include breakpoint(above, 'xx-large') {
    left: 120px;
    margin-top: 30px;
  }

  // 1: Safari doesn't scale the logo correctly on interior pages, so we need
  //    to explicitly set the height and width here.
  //
  // 2: IE 11 doesn't support CSS transitions on SVG properties, so this
  //    transition will never show up in that browser.
  //
  //    @see https://developer.microsoft.com/en-us/microsoft-edge/platform/status/csstransitionsforsvgelements/
  svg {
    @include rectangle(
      $masthead-logo-width--interior,
      $masthead-logo-height--interior
    ); // 1
    display: block;
    fill: $white;
    transition: fill get-timing(normal); // 2
  }

  &:hover,
  &:focus {
    svg {
      fill: $accent;
    }
  }
}


// =============================================================================
// Homepage
// -----------------------------------------------------------------------------

.front {
  .masthead__logo {
    left: 85px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;

    @include breakpoint(above, 'x-small') {
      left: 50%;
      margin-top: 30px;
      transform: translateX(-50%);
    }

    @include breakpoint(above, 'small') {
      height: 45px;
      margin-top: 40px;
    }

    @include breakpoint(above, 'medium') {
      height: 60px;
    }

    @include breakpoint(above, 'large') {
      margin-top: 50px;
    }

    svg {
      fill: $light-gray;
      height: 28px;
      width: auto;

      @include breakpoint(above, 'small') {
        height: 45px;
      }

      @include breakpoint(above, 'medium') {
        height: 60px;
      }
    }

    &:hover,
    &:focus {
      svg {
        fill: $accent;
      }
    }
  }
}


// =============================================================================
// User Pages
// -----------------------------------------------------------------------------

.section-user,
.section-users {
  .masthead__logo {
    svg {
      fill: $gray;
    }

    &:hover,
    &:focus {
      svg {
        fill: $accent;
      }
    }
  }
}
